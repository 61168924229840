<script setup lang="ts">
// Types.
//
import { type Alert } from '@/stores/alerts'
// Properties and events.
//
const props = defineProps<{
  alert: Alert
}>()
// Main variables.
//
const alert = toRef(props, 'alert')
// Functions.
//
const clearAlert = function clearAlert() {
  useAlertStore().dismissAlert(alert.value)
}
</script>

<template>
  <div
    id="toastAlert"
    class="toast show mb-3"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :class="alert.type"
  >
    <div class="toast-header">
      <strong class="me-auto">System Alert</strong>
      <span
        class="rk-icon rk-icon--text-xs fontello icon-sys-close"
        data-bs-dismiss="toast"
        aria-label="Close"
        @click="clearAlert"
      ></span>
    </div>
    <div class="toast-body">
      <p>{{ alert.message }}</p>
    </div>
  </div>
</template>

<style lang="scss"></style>

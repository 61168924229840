<template>
  <div
    class="rk-body"
    :class="{
      'active-menu': menuStatus,
    }"
  >
    <NuxtLayout v-if="loggedIn && !isOAuth" name="app-menu" />
    <div
      class="rk-body__content"
      :class="{
        'pe-0 pe-xxl-5': loggedIn && !isOAuth,
      }"
    >
      <NuxtLayout v-if="loggedIn && !isOAuth" name="app-header" />
      <div
        class="container-fluid position-relative px-3"
        :class="{
          'active-mobile-menu': menuStatus,
        }"
      >
        <div class="row">
          <div class="col">
            <div
              :class="{
                'rk-card rk-card--main': loggedIn && !isOAuth,
                'overflow-visible': datePickerOpen,
              }"
            >
              <NuxtLayout
                v-if="route !== '/' && loggedIn && !isOAuth"
                name="app-breadcrumbs"
              />
              <slot />
            </div>
          </div>
        </div>
        <div class="rk-overlay light" @click="closeMenu">
          <div class="rk-overlay__icon d-flex align-items-center">
            <i class="rk-icon rk-icon--text-sm fontello icon-arrow-left"></i>
            <i class="rk-icon rk-icon--text-lg fontello icon-close-sidebar"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NuxtLayout name="app-footer" />
  <div
    v-if="
      stateOverlays && stateOverlays.length >= 1 && stateOverlays.length <= 2
    "
    class="rk-overlay show level-1"
    @click="setOverlayToClosed(1)"
  ></div>
  <div
    v-if="stateOverlays && stateOverlays.length === 2"
    class="rk-overlay show level-2"
    @click="setOverlayToClosed(2)"
  ></div>
  <div
    v-if="alerts && alerts.length > 0"
    class="toast-container position-fixed top-0 p-3"
  >
    <template v-for="(alert, index) in alerts" :key="`alert-${index}`">
      <ToastAlert :alert="alert" />
    </template>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  computed: {
    route() {
      return this.$route.fullPath
    },
    menuStatus() {
      return useGlobalStore().menu
    },
    loggedIn() {
      return useAuthStore().token
        ? useAuthStore().token && useAuthStore().token!.length > 0
        : null
    },
    isOAuth() {
      return this.route.includes('oauth') || false
    },
    datePickerOpen() {
      return useGlobalStore().datePickerOpen
    },
    stateOverlays() {
      return useGlobalStore().overlays
    },
    alerts() {
      return useAlertStore().alerts
    },
    showRecentlyViewed() {
      return useGlobalStore().recentlyViewed
    },
    darkMode() {
      return useGlobalStore().darkMode
    },
  },
  data() {
    return {
      html: document.documentElement as HTMLElement,
    }
  },
  methods: {
    closeMenu() {
      useGlobalStore().menu = false
    },
    setOverlayToClosed(lvl: number) {
      useGlobalStore().removeOverlay({ level: lvl })
    },
    onRouteChange() {
      if (this.stateOverlays.length > 0) {
        useGlobalStore().overlays = []
      }
      if (this.showRecentlyViewed) {
        useGlobalStore().toggleRecentlyViewed()
      }
    },
    onThemeChange() {
      this.darkMode
        ? this.html?.setAttribute('data-bs-theme', 'dark')
        : this.html?.setAttribute('data-bs-theme', 'default')
    },
  },
  mounted() {
    this.onThemeChange()
  },
  watch: {
    $route: [{ immediate: true, deep: true, handler: 'onRouteChange' }],
    darkMode: [{ handler: 'onThemeChange' }],
  },
})
</script>

<style lang="scss"></style>
